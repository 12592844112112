function Prglan(){
    return(
        <div>
            <div class="lol">
            <div class="code">
            <p class="ob4"><b>Programming Languages:</b></p>
            <ul class="ob6">
                <li>c</li>
                <li>c++</li>
                <li>Java</li>
            </ul>    
            </div>
            <div class="code"> 
            <ul>
                <li>Html</li>   
                <li>Css</li>
                <li>Java Script</li>
                <li>SQL</li>
            </ul>
            </div>
            </div>
        </div>
    );
}
export default Prglan;