function CompCode(){
    return(
        <div class="lol">
        <div class="code">
        <p><b>COMPETETIVE CODING: </b></p>
        <ol class="ob1">
            <li><a href="http://www.skillrack.com/profile/419096/8b3f7428c883b6c9a6d330c5dbc02c2d0b18ff08" target="_blank"><b>SKILLRACK</b></a></li>
            <li>Hacker Rank : @babubalaji877841</li>
        </ol>
        </div>
        </div>
    );
}
export default CompCode;